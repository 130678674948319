<template>
  <div class="modal fade" id="campaign-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="campaign-modalLabel">Campaign</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="mb-3 d-flex">
            <div class="n-chk">
              <label class="new-control new-radio radio-classic-primary" for="media_fb">
                <input class="new-control-input form-control"
                       type="radio"
                       name="media"
                       id="media_fb"
                       @change="changeMedia"
                       v-model="form.type"
                       value="fb">
                <span class="new-control-indicator"></span>
                <span class="new-radio-content">Facebook</span>
              </label>
            </div>
            <div class="n-chk">
              <label class="new-control new-radio radio-classic-primary" for="media_google">
                <input class="new-control-input form-control"
                       type="radio"
                       name="media"
                       @change="changeMedia"
                       v-model="form.type"
                       id="media_google"
                       value="google">
                <span class="new-control-indicator"></span>
                <span class="new-radio-content">Google</span>
              </label>
            </div>
            <div class="n-chk">
              <label class="new-control new-radio radio-classic-primary" for="media_influencer">
                <input class="new-control-input form-control"
                       type="radio"
                       name="media"
                       @change="changeMedia"
                       v-model="form.type"
                       id="media_influencer"
                       value="influencer">
                <span class="new-control-indicator"></span>
                <span class="new-radio-content">Influencer/Manual</span>
              </label>
            </div>

          </div>

          <div v-if="form.type === 'influencer'">
            <div class="form-group">
              <label for="budget">Spend:</label>
              <div class="row">
                <div class="col-md-6">
                  <div class="custom-control custom-checkbox checkbox-primary pl-0">
                    <input type="checkbox" class="custom-control-input" id="basicChk2" value="fixed" v-model="form.isFixedAmount">
                    <label class="custom-control-label" for="basicChk2">Fixed Amount</label>
                  </div>
                  <div class="pl-1">
                    <div class="input-group">
                      <input type="number" :disabled="!form.isFixedAmount" v-model="form.fixedAmount" class="form-control" placeholder="Amount" />

                      <div class="input-group-append">
                        <button class="btn btn-dark dropdown-toggle"
                                type="button"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false">{{ form.currency }} </button>
                        <div class="dropdown-menu currency-dropdown-menu">
                          <a class="dropdown-item dropdown-item-sm"
                             v-for="currency in currencies"
                             :key="'currency_'+currency.id"
                             @click="form.currency = currency.code">{{ currency.code }}</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="custom-control custom-checkbox checkbox-primary pl-0">
                    <input type="checkbox" class="custom-control-input" id="basicChk3" value="percentage" v-model="form.isPercentageAmount">
                    <label class="custom-control-label" for="basicChk3">Percentage of Sales</label>
                  </div>
                  <div class="pl-1">
                    <input type="number" v-model="form.percentageAmount" :disabled="!form.isPercentageAmount" class="form-control" placeholder="Percentage" />
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12 m-auto">
                <div class="form-group">
                  <label for="budget">Influencer Email(s)</label>

                  <select class="select2 influencer_emails form-control"
                          style="width: 100%"
                          multiple="multiple"
                          data-placeholder="Enter Keywords">
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <div class="mb-3">
              <template v-if="(form.type === 'fb' && !loadingFbAccounts) || (form.type === 'google' && !loadingGoogleAccounts)">
                <label class="form-label">{{ form.type === 'fb' ? 'Facebook Account' : 'Google Account' }}</label>
                <select class="form-control"
                        v-model="form.account_id"
                        @change="changeAccount($event.target.value)">
                  <option value="">Select Account</option>
                  <option :value="account.id"
                          v-for="account in accounts"
                          :key="'account_'+account.id">{{ account.name }}</option>
                </select>
              </template>

              <div class="spinner-border text-black  align-self-center loader-sm " v-else></div>
            </div>

            <div class="text-center" v-if="loading || googleAdsLoading">
              <div class="spinner-border" role="status">
                <span class="visually-hidden"></span>
              </div>
            </div>

            <div class="mb-3" v-if="!loading && form.type === 'fb'">
              <label class="form-label">{{ form.type === 'fb' ? 'Facebook Campaign' : 'Google Campaign' }}</label>
              <select class="form-select form-control"
                      id="fb-campaign-select"
                      :disabled="!form.account_id"
                      v-model="form.fb_campaign">
                <option value="">Select Campaign</option>

                <optgroup label="Active">
                  <option :value="campaign.id"
                          v-for="campaign in fbCampaigns.filter(c => c.status === 'ACTIVE')"
                          :key="'campaign_'+campaign.id">{{ campaign.name }}</option>
                </optgroup>
                <optgroup label="Inactive">
                  <option :value="campaign.id"
                          v-for="campaign in fbCampaigns.filter(c => c.status !== 'ACTIVE')"
                          :key="'campaign_'+campaign.id">{{ campaign.name }}</option>
                </optgroup>
              </select>
            </div>

            <div class="mb-3"  v-if="!googleAdsLoading && form.type === 'google'">
              <label class="form-label">{{ form.type === 'fb' ? 'Facebook Campaign' : 'Google Campaign' }}</label>
              <select class="form-select form-control"
                      id="google-campaign-select"
                      :disabled="!form.account_id"
                      v-model="form.google_campaign">
                <option value="">Select Campaign</option>

                <optgroup label="Active">
                  <option :value="campaign.id"
                          v-for="campaign in googleCampaigns.filter(c => c.status === 'Enabled')"
                          :key="'campaign_'+campaign.id">{{ campaign.name }}</option>
                </optgroup>
                <optgroup label="Inactive">
                  <option :value="campaign.id"
                          v-for="campaign in googleCampaigns.filter(c => c.status !== 'Enabled')"
                          :key="'campaign_'+campaign.id">{{ campaign.name }}</option>
                </optgroup>
              </select>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button class="btn btn-primary btn-rounded my-2"
                  @click.prevent="submit">Save changes
          </button>
          <button type="button" class="btn btn-dark btn-rounded my-2" data-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  props: {
    amazonCampaign: {
      type: Object,
      require: false
    }
  },
  watch: {
    fbAdAccounts(accounts) {
      if (this.form.type === 'fb')
        this.accounts = accounts;
    },
    amazonCampaign: {
      handler(campaign) {
        this.form.amazon_campaign = campaign ? campaign.name : '';
      },
      deep: true
    }
  },
  data() {
    return {
      currencies: [],
      googleCampaigns: [],
      fbCampaigns: [],
      googleAdsLoading: false,
      form: {
        type: 'fb',
        account_id: '',
        fb_account_id: '',
        amazon_campaign: '',
        fb_campaign: '',
        fb_campaign_name: '',
        google_campaign: '',
        google_campaign_name: '',
        isFixedAmount: false,
        currency: 'USD',
        fixedAmount: null,
        isPercentageAmount: false,
        percentageAmount: null,
        emails: []
      }
    }
  },
  created() {
    this.loadCurrencies();
  },
  mounted() {
    this.initInfluencerEmailsSelect2();
  },
  computed: {
    ...mapGetters({
      fbAdAccounts: 'adAccounts/getFbAccounts',
      googleAccounts: 'adAccounts/getGoogleAccounts',
      loadingFbAccounts: 'adAccounts/getLoadingFbAccounts',
      loadingGoogleAccounts: 'adAccounts/getLoadingGoogleAccounts',
    }),
    accounts() {
      if (this.form.type === 'fb') {
        return this.fbAdAccounts;
      } else {
        return this.googleAccounts;
      }
    }
  },
  methods: {
    loadCurrencies() {
      this.axios.get('/currencies')
        .then(res => {
          this.currencies = res.data.data;
        })
    },
    initInfluencerEmailsSelect2() {
      let self = this;

      window.$('.influencer_emails').select2({
        tokenSeparators: ',',
        tags: true,
        dropdownParent: window.$('#campaign-modal')
      });

      window.$('.influencer_emails').on('change', function () {
        self.form.emails = window.$(".influencer_emails").select2("val");
      });
    },
    initSelect2(id, payload) {
      let self = this;

      window.$('#' + id).select2({
        dropdownParent: window.$('#campaign-modal')
      });

      window.$('#' + id).on('change', function () {
        self.form[payload] = window.$('#' + id).select2("val");
      });
    },
    changeMedia() {
      this.form.account_id = '';
      this.initInfluencerEmailsSelect2();

      /*if (this.form.type === 'fb') {
        this.accounts = this.fbAdAccounts;
      } else {
        this.accounts = this.googleAccounts;
      }*/
    },
    changeAccount(account) {
      if (this.form.type === 'fb') {
        this.changeFbAccount(account);
      } else {
        this.changeGoogleAccount(account);
      }
    },
    changeFbAccount(account) {
      this.fbCampaigns = [];
      this.form.fb_campaign = '';

      if (account) {
        this.loadCampaigns(account)
      }
    },
    changeGoogleAccount(profile) {
      this.googleCampaigns = [];
      this.form.google_campaign = '';

      if (profile) {
        this.googleAdsLoading = true;

        this.axios.get('/google/campaigns', {
          params: {
            id: profile
          }
        }).then(response => {
          this.googleCampaigns = response.data.data;

          setTimeout(() => {
            this.initSelect2('google-campaign-select', 'google_campaign')
          }, 500);
        }).finally(() => {
          this.googleAdsLoading = false;
        })
      }
    },
    loadCampaigns(act) {
      this.loading = true;

      this.axios.get('facebook/campaigns', {
          params: {
            act: act
          }
        })
        .then(response => {
          this.fbCampaigns = response.data.data;

          setTimeout(() => {
            this.initSelect2('fb-campaign-select', 'fb_campaign')
          }, 500);
        })
        .finally(() => {
          this.loading = false;
        })
    },
    submit() {
      this.loading = true;

      let campaign = this.fbCampaigns.find(c => c.id == this.form.fb_campaign)

      let googleCampaign = this.googleCampaigns.find(c => c.id == this.form.google_campaign)

      if (campaign)
        this.form.fb_campaign_name = campaign.name;
      else
        this.form.fb_campaign_name = '';

      if (googleCampaign)
        this.form.google_campaign_name = googleCampaign.name;
      else
        this.form.google_campaign_name = '';

      this.axios.post('/set-campaign', this.form)
        .then(() => {
          this.$emit('campaignUpdate', this.form);
        })
        .finally(() => this.loading = false)
    }
  }
}
</script>